.assigned{
  color: orange;
  margin-right: 3px;
  cursor: pointer;
}

.avatar{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.avatar-md{
  width: 100px;
  height: 100px;
  border-radius: 50%
}

.avatar-sm{
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.btn-fake{
  background: #3490dc;
  color: white;
  border: 1px solid #3490dc;
  border-radius: 3px;
}

#caption{
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

.declined{
  background-color: #666 !important;
  color: #ccc !important;
  margin-right: 3px;
  cursor: pointer;
}

.fa-hand-holding-usd{
  color: darkolivegreen;
  margin-right: 3px;
  cursor: pointer;
}

.fa-info-circle{
  color: #818182;
  margin-right: 3px;
  cursor: pointer;
}

.fa-star{
  color: #ff9900;
  margin-right: 3px;
  cursor: pointer;
}

.fai-header{
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: #E30613;
  padding: .25rem;
  color: white;
  font-weight: bold;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.fai-title{
  color: #E30613;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.fai-cell{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: .25rem;
  color: black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.fai-cell-grey{
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: #ccc;
  padding: .25rem;
  color: black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-weight: bold;
}

.fai-input{
  width: 100%;
  border: none;
  color: black;
  background-color: #ddd;
  margin-left: 4px;
  padding: 2px 6px;
}

.imageModal{
  display: none;
  position: fixed;
  z-index: 10000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
}

.informed{
  color: darkblue;
  margin-right: 3px;
  cursor: pointer;
}

.interested, .accepted{
  color: #38c172;
  margin-right: 3px;
  cursor: pointer;
}

.reserved{
  background-color: #a08080 !important;
  color: #3c0000 !important;
  margin-right: 3px;
  cursor: pointer;
}

.interested:hover, .accepted:hover, .assigned:hover, .reserved:hover{
  filter: brightness(0.75);
}

.mark-as-read{
  cursor: pointer;
}

.message-close{
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.modal-close{
  position: absolute;
  top: 10px;
  right: 15px;
  color: #6c757d;
  font-size: 32px;
  font-weight: bold;
  transition: 0.3s;
}

.modal-close:hover,
.modal-close:focus{
  color: #5a6268;
  text-decoration: none;
  cursor: pointer;
}

.modal-content, #caption{
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

.modal-image{
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.modal-image:hover,
.modal-image:focus{
  filter: brightness(115%);
  cursor: pointer;
}

.modal-image-close{
  position: absolute;
  top: 20px;
  right: 35px;
  color: #fafafa;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.modal-image-close:hover,
.modal-image-close:focus{
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.noselect{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.page-heading{
  font-weight: 700 !important;
  margin-bottom: 0 !important;
  color: #535b5f;
}

.page-style{
  background-color: #fff !important;
  border-radius: 0.25rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  width: 85% !important;
}

.project-notes{
  max-height: 350px;
  overflow: auto;
}

.project-note-text{
  white-space: pre-line;
}

.popover{
  max-width: 350px;
}

.sortableList{
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow-y: scroll;
  list-style-type: none;
  padding: 5px 0 0 0;
  margin: 0 10px 0 0;
  background: #f4f4f4;
}

.sortableList li{
  margin: 0 5px 2px 5px;
  border-radius: 5px;
  padding: 1px 5px 1px 5px;
}

.sortableList li:hover{
  cursor: grab;
}

.sortableList li.ui-sortable-helper{
  cursor: grabbing;
}

.sub-heading{
  font-weight: 700 !important;
  margin-top: 0.25rem !important;
  margin-bottom: 0 !important;
  color: #636b6f;
}

.team-group{
  max-height: 487px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.myImg{
  cursor: pointer;
}

.thumbnail{
  border-radius: 5px;
  transition: 0.3s;
  height: 100%;
  max-height: 100px;
}

.thumbnail:hover{
  opacity: 0.7;
}

.workforce-filter{
  position: relative;
  padding: 18px 15px 15px;
}

.workforce-filter:after{
  content: "Filter Workforce";
  position: absolute;
  top: 0;
  left: 1rem;
  font-size: 11px;
  font-weight: 700;
  color: #444;
  letter-spacing: 1px;
}

:root{
  --star-size: 20px;
  --star-color: #dddddd;
  --star-background: #ec2324;
}

.stars{
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  line-height: 1;
}

.stars::before{
  content: '★★★★★';
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fireguard-card{
  background: linear-gradient(to bottom, #ddf0ff 0%, #f8fcff 20%, #f8fcff 85%, #ddf0ff 100%);
}

.supervisor-card{
  background: linear-gradient(to bottom, #ddfff0 0%, #fafffc 20%, #fafffc 85%, #ddfff0 100%);
}

.feature-card{
  margin: 0 0 0 1rem;
  width: 28%;
  max-width: 250px;
  height: 300px;
  max-height: 300px;
}

.admin-card{
  background: linear-gradient(to bottom, #fbdedf 0%, #fefbfb 20%, #fefbfb 85%, #fbdedf 100%);
}


#body-row{
  margin-left: 0;
  margin-right: 0;
  background: #eee;
}

/* Sidebar sizes when expanded and expanded */
.sidebar-expanded{
  width: 220px;
}

.sidebar-collapsed{
  width: 60px;
}

/* Menu item*/
#sidebar-container .list-group a{
  height: 50px;
  color: #333;
}

/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a{
  height: 45px;
  padding-left: 30px;
}

.sidebar-submenu{
  font-size: 0.9rem;
}

#sidebar-container{
  position: fixed;
  top: 70px;
  min-height: 100vh;
  background-color: white;
  padding: 0;
  z-index: -1;
}

/* Separators */
.sidebar-separator-title{
  background-color: #f7f7f7;
  height: 35px;
}

.sidebar-separator{
  background-color: #eee;
  height: 25px;
}

.logo-separator{
  background-color: #eee;
  height: 60px;
}

.list-group-item-action{
  background-color: white;
}

/* Closed submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="false"] .submenu-icon::after{
  content: " \25bc";
  display: inline;
  text-align: right;
  padding-left: 10px;
}

/* Opened submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="true"] .submenu-icon::after{
  content: " \25b2";
  display: inline;
  text-align: right;
  padding-left: 10px;
}

.col-main{
  position: absolute;
  top: 70px;
  width: 100%;
  z-index: 1;
}

.col-main-sidebar-expanded{
  padding: 12px 12px 12px 212px;
}

.col-main-sidebar-collapsed{
  padding: 12px 12px 12px 72px;
}

.brand-width{
  width: 120px;
  text-align: center;
}

.brand-img{
  width: 100px;
  text-align: center;
}

.brand-heading{
  font-size: 2rem;
}

@media (max-width: 768px){
  .brand-heading{
    font-size: 1.75rem;
  }

  .brand-img{
    width: 75px;
  }

  .brand-width{
    width: 100px;
  }
}

@media (max-width: 580px){
  .brand-heading{
    font-size: 1.25rem;
  }
}

@media (max-width: 470px){
  .brand-heading{
    display: none;
  }
}

@keyframes zoom{
  from{
    transform: scale(0)
  }
  to{
    transform: scale(1)
  }
}

@media only screen and (max-width: 700px){
  .modal-image{
    width: 100%;
  }
}

@-webkit-keyframes zoom{
  from{
    -webkit-transform: scale(0)
  }
  to{
    -webkit-transform: scale(1)
  }
}

/* Cookie Consent Banner */
.cookie-consent{
  font-size: 0.9em;
  padding: 1em;
  text-align: center;
}

.cookie-consent__message{
  display: inline-block;
  color: #df1e26;
}

.cookie-consent__message p{
  margin: 0;
}

.cookie-consent__agree{
  font-weight: bold;
  margin: 0 1em;
  padding: .5em 1em;
  color: #fff2e0;
  background-color: #df1e26;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgb(81, 4, 4);
}

.cookie-consent__agree:hover{
  background-color: #940007;
}

.cookie-consent__agree:active{
  top: 1px;
}

.select2-selection__choice{
  color: black !important;
}

/* The popup form - hidden by default */
.form-popup{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 999;
}

.form-popup-content{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 550px;
  max-height: 650px;
  overflow-y: auto;
  background-color: white;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}

.form-popup-header{
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f0f0f0;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.form-popup-body{
  min-height: 1px;
  padding: 0.75rem 1.25rem;
}

.form-popup-footer{
  display: flex;
  justify-content: right;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid #e9ecef;
}

.spinnner-overlay{
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.8) url("/images/loader.gif") center no-repeat;
}

/* Make spinner image visible when body element has the loading class */
.loading-zone.loading .spinnner-overlay{
  display: block;
}

.bg-gray-100{
  background-color: #f7fafc;
}

.border{
  border-width: 1px;
}

.border-gray-400{
  border-color: #cbd5e0;
}

.shadow-xl{
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
}

.rounded{
  border-radius: .25rem;
}

.whitespace-no-wrap{
  white-space: nowrap;
}

.bg-blue-200{
  background-color: #bee3f8;
}

.border-b{
  border-bottom-width: 1px;
}

.hover\:bg-blue-200:hover{
  background-color: #e5e5e5;
  cursor: pointer;
}

.status-selected{
  background-color: #e5e5e5;
}

.menu-active-item{
  filter: brightness(0.75);
}

.onoffswitch{
  position: relative;
  width: 66px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox{
  display: none;
}

.onoffswitch-label{
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #999;
  border-radius: 20px;
}

.onoffswitch-inner{
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after{
  display: block;
  float: left;
  width: 50%;
  height: 25px;
  padding: 0;
  line-height: 25px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner:before{
  content: "Yes";
  padding-left: 10px;
  background-color: #34A7C1;
  color: #FFF;
}

.onoffswitch-inner:after{
  content: "No";
  padding-right: 10px;
  background-color: #EEE;
  color: #999;
  text-align: right;
}

.onoffswitch-switch{
  display: block;
  width: 17px;
  margin: 4px;
  background: #FFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 37px;
  border: 2px solid #999;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner{
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch{
  right: 0px;
}
