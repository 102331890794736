// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'customisation';
@import 'variables';

// Admin LTE Integration
@import '~admin-lte/build/scss/adminlte';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// JQuery UI
@import '~jquery-ui/themes/base/all.css';

// Custom CSS
@import '../css/custom.css';
